import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import classnames from 'classnames';
import Link from 'gatsby-link';
import Img from 'gatsby-image';

const CarouselItem = ({ index, image, title, description, link }) => (
  <Link
    to={link}
    className={classnames({
      'carousel-item': true,
      active: index === 0,
    })}
  >
    <Img
      className="d-block w-100"
      fluid={image.childImageSharp.fluid}
      alt={title}
    />
    <div className="carousel-caption">
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  </Link>
);

class HomeImages extends Component {
  componentDidMount() {
    $('#homeImages').carousel();
  }

  render() {
    const { images } = this.props;

    return (
      <div
        id="homeImages"
        className="homeImages carousel slide w-100"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {images.map((image, index) => (
            <li
              key={index}
              data-target="#homeImages"
              data-slide-to={index}
              className={classnames({ active: index === 0 })}
            />
          ))}
        </ol>

        <div className="carousel-inner">
          {images.map((image, index) => (
            <CarouselItem key={index} index={index} {...image} />
          ))}
        </div>

        <a
          className="carousel-control-prev"
          href="#homeImages"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#homeImages"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

HomeImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }).isRequired,
  ),
};

export default HomeImages;
