import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import HomeImages from '../components/HomeImages';
import { HTMLContent } from '../components/Content';

export const HomePageTemplate = ({
  content,
  homeImages,
  rooms,
  booking,
  trebon,
}) => (
  <article className="home">
    <header className="home__images row d-none d-lg-block">
      <HomeImages images={homeImages} />
    </header>

    <section className="home__content row">
      <div className="col-sm">
        <HTMLContent content={content} />
      </div>
    </section>
  </article>
);

export default ({ data, location }) => {
  const { markdownRemark: home, allMarkdownRemark: homeImages, site } = data;

  return (
    <Layout location={location}>
      <Helmet title={site.siteMetadata.title} />
      <HomePageTemplate
        content={home.html}
        homeImages={get(homeImages, 'edges', []).map(i => i.node.frontmatter)}
        rooms={{
          image: home.frontmatter.imageRooms.childImageSharp.fluid,
          label: home.frontmatter.labelRooms,
        }}
        booking={{
          image: home.frontmatter.imageReservation.childImageSharp.fluid,
          label: home.frontmatter.labelReservation,
        }}
        trebon={{
          image: home.frontmatter.imageTrebon.childImageSharp.fluid,
          label: home.frontmatter.labelTrebon,
        }}
        location={location}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomePageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      html
      frontmatter {
        imageRooms {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageTrebon {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageReservation {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        labelRooms
        labelReservation
        labelTrebon
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "HomeImage" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            type
            image {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            link
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
